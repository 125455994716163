import { Query } from 'react-apollo';
import constants from 'constants/constants';
import sitemapIndexQuery from 'graphql/queries/sitemapIndex.graphql';
import Status from 'components/atoms/Status';

const SitemapIndex = ({ staticContext }) => (
  <Query query={sitemapIndexQuery}>
    {({ loading, data, error }) => {
      if (loading) {
        return '';
      }

      if (error) {
        return <Status code={500} />;
      }

      if (staticContext) {
        staticContext.disableLayout = true;
        staticContext.contentType = 'text/xml';
        staticContext.unsafeBeforeString = '<?xml version="1.0" encoding="UTF-8"?>\n';
      }

      return (
        <sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
          <sitemap>
            <loc>{`${constants.BASE_URL}/sitemap-static.xml`}</loc>
          </sitemap>
          <sitemap>
            <loc>{`${constants.BASE_URL}/sitemap-catalog.xml`}</loc>
          </sitemap>
          {Array(Math.ceil(data.itemsConnection.aggregate.count / 1000))
            .fill()
            .map((_, i) => (
              <sitemap>
                <loc>{`${constants.BASE_URL}/sitemap-items-${i + 1}.xml`}</loc>
              </sitemap>
            ))}
          <sitemap>
            <loc>{`${constants.BASE_URL}/sitemap-news.xml`}</loc>
          </sitemap>
          <sitemap>
            <loc>{`${constants.BASE_URL}/sitemap-sales.xml`}</loc>
          </sitemap>
        </sitemapindex>
      );
    }}
  </Query>
);

export default SitemapIndex;
